import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Calendar, MapPin } from "lucide-react";
import { getCategoryStyle } from "./categoryColors";

const EventCard = ({ event, index }) => {
  // Load the calendar script - placed before any conditional returns
  useEffect(() => {
    // Check if script is already loaded
    if (!document.getElementById('add-to-calendar-script')) {
      const script = document.createElement('script');
      script.id = 'add-to-calendar-script';
      script.src = 'https://cdn.jsdelivr.net/npm/add-to-calendar-button@2';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);

  // Safeguards for missing data
  if (!event) return null;
  
  // Convert category name to CSS-friendly class name
  const categoryClass = event.category ? event.category.toLowerCase().replace(/[^a-z0-9]/g, '-') : 'unknown';

  // Get style for the category from our centralized source
  const categoryStyle = getCategoryStyle(event.category);
  
  // Debug logging
  console.log('EventCard Category:', event.category);
  console.log('CategoryClass:', categoryClass);
  console.log('CategoryStyle:', categoryStyle);
  
  // Format date for calendar button
  const formatDateForCalendar = (dateString) => {
    if (!dateString) return '';
    // Try to detect date format and parse accordingly
    if (dateString.includes('/')) {
      const parts = dateString.split('/');
      // Assuming DD/MM/YYYY format
      if (parts.length === 3) {
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    return dateString; // Return as is if format is unknown
  };

  return (
    <motion.article
      className="event-card group"
      whileHover={{ y: -4 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.3,
        delay: index * 0.1,
        ease: [0.23, 1, 0.32, 1]
      }}
    >     
      <a 
        href={event.link || '#'}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col h-full justify-between"
        style={{ textDecoration: 'none', flex: '1 1 auto' }}
        aria-label={`Event: ${event.title || 'Untitled'} ${event.location ? `at ${event.location}` : ''}`}
      >
        <div className="event-content">
          <h3 className="event-title mb-2">{event.title || 'Untitled Event'}</h3>
          {event.location && (
            <div className="location-container">
              <span className="location-icon" aria-hidden="true">
                <MapPin size={18} color="#1124c0" />
              </span>
              <span className="event-location">{event.location}</span>
            </div>
          )}
          {event.date && (
            <div className="date-container">
              <span className="date-icon" aria-hidden="true">
                <Calendar size={18} color="#1124c0" />
              </span>
              <time className="event-date" dateTime={event.cleaned_dates || event.date}>
                {event.date}
              </time>
            </div>
          )}
        </div>

        <div className="category-wrapper">
          <span 
            className={`event-category ${categoryClass}`}
            style={categoryStyle}
          >
            {event.category || 'Unknown'}
          </span>
        </div>
      </a>
      
      {/* Add to Calendar Button - Positioned at the bottom with clear separation */}
      <div className="calendar-button-container">
        <add-to-calendar-button
          name={event.title || 'Untitled Event'}
          description={event.description || ''}
          startDate={formatDateForCalendar(event.cleaned_dates || event.date)}
          location={event.location || ''}
          options="['Apple','Google']"
          timeZone="Europe/Brussels"
          trigger="click"
          buttonStyle="text"
          listStyle="overlay"
          forceOverlay="true"
          size="2"
          label="Calendar"
          styleLight="--btn-background-hover: #081055; --btn-text-hover: #ffffff; --btn-shadow: none; --list-background: #fff; --list-text: #333; --list-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); --btn-border: none; --list-border-radius: 4px; --font-size: 12px; --btn-padding: 6px 10px;"
          hideBranding
          hideCheckmark
          touchSupport="false"
          allDay="false"
          inline="true"
        ></add-to-calendar-button>
      </div>
    </motion.article>
  );
};

export default EventCard;
