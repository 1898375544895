import React, { useState, useEffect } from "react";
import eventsDataNl from "../data/events.json";
import eventsDataEn from "../data/events_en.json";
import { motion } from "framer-motion";

const UpcomingEventsCounter = ({ language }) => {
  const [count, setCount] = useState(0);
  const [displayCount, setDisplayCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  
  // Check if we're on mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  useEffect(() => {
    // Get events data based on language
    const eventsData = language === 'nl' ? eventsDataNl : eventsDataEn;
    
    // Calculate today and 7 days ahead
    const today = new Date().toISOString().split('T')[0];
    const sevenDaysAhead = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0];
    
    // Count events in the next 7 days
    const upcomingEventsCount = eventsData.filter(event => 
      event.cleaned_dates >= today && event.cleaned_dates <= sevenDaysAhead
    ).length;
    
    // Update the counter
    setCount(upcomingEventsCount);
  }, [language]);

  // Animate the counter
  useEffect(() => {
    if (count === 0) return;
    
    // Start from 0 and count up
    let start = 0;
    const duration = 1500; // 1.5 seconds
    const interval = 20; // Update every 20ms
    const increment = Math.max(1, Math.floor(count / (duration / interval)));
    
    const timer = setInterval(() => {
      start += increment;
      if (start >= count) {
        setDisplayCount(count);
        clearInterval(timer);
      } else {
        setDisplayCount(start);
      }
    }, interval);
    
    return () => clearInterval(timer);
  }, [count]);
  
  return (
    <motion.div 
      className="week-events-counter"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      {isMobile ? (
        // Mobile layout (more compact)
        <>
          <div className="counter-number">{displayCount}</div>
          <div className="counter-label" style={{ fontSize: '0.6rem' }}>
            {language === 'nl' ? 'Deze Week' : 'This Week'}
          </div>
        </>
      ) : (
        // Desktop layout
        <>
          <div className="counter-label">
            {language === 'nl' ? 'Deze Week' : 'This Week'}
          </div>
          <div className="counter-number">{displayCount}</div>
          <div className="counter-label">
            {language === 'nl' ? 'Events' : 'Events'}
          </div>
        </>
      )}
    </motion.div>
  );
};

export default UpcomingEventsCounter; 