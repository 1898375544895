// src/App.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import EventList from "./components/EventList";
import "./styles.css";
import { motion, useScroll } from "framer-motion";
import { Analytics } from "@vercel/analytics/react";
import LanguageSwitcher from "./components/LanguageSwitcher";
import UpcomingEventsCounter from "./components/UpcomingEventsCounter";
import StructuredData, { 
  generateWebsiteSchema, 
  generateLocalBusinessSchema,
  generateFAQSchema
} from "./components/StructuredData";
import CanonicalLink from "./components/CanonicalLink";

// Add this simple function to prevent scrolling
function preventScroll() {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
}

function App() {
  const [language, setLanguage] = useState('nl');
  const [eventSubmission, setEventSubmission] = useState('');
  const [currentPath, setCurrentPath] = useState('/');
  const [randomDrawings, setRandomDrawings] = useState([]);
  const appRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const hasGeneratedDrawings = useRef(false);
  const lastDeviceIsMobile = useRef(window.innerWidth <= 768);
  const lastGeneratedHeight = useRef(0); // Ref to track the height used for last generation
  const isInitialRender = useRef(true); // Track initial render
  const [isLoaded, setIsLoaded] = useState(false); // Track when initial content is loaded

  // Hook to get the scroll progress
  const { scrollYProgress } = useScroll();

  // Force scroll position to top - simplest direct solution
  useEffect(() => {
    // Force scroll to top initially
    preventScroll();
    
    // Add scroll event listener to prevent scrolling during initial load
    const handleScroll = () => {
      if (isInitialRender.current) {
        preventScroll();
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Guaranteed scroll reset after everything is loaded
    const timeoutId = setTimeout(() => {
      preventScroll();
      isInitialRender.current = false;
    }, 500);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  // Update path if it changes
  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  // Define animation variants for the drawing effect
  const drawingVariants = {
    hidden: {
      pathLength: 0,
      opacity: 0,
    },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 2.5,
      },
    },
  };

  // List of SVG drawings in the public/drawings folder
  const drawings = [
    "biker.svg",
    "booktower.svg",
    "coffee.svg",
    "dancers.svg",
    "film.svg",
    "flowermarket.svg",
    "football.svg",
    "jupiler.svg",
    "masks.svg",
    "reader.svg",
    "walking person.svg",
    "mannetjes1.svg",
    "mannetjes2.svg",
    "mannetjes3.svg",
    "mannetjes4.svg",
    "mannetjes5.svg",
  ];

  // Make sure drawings are loaded before rendering
  useEffect(() => {
    // Preload the images to ensure they're ready
    drawings.forEach(drawing => {
      const img = new Image();
      img.src = `/drawings/${drawing}`;
    });
  }, [drawings]);

  // Generate random drawings - wrapped in useCallback to avoid ESLint warning
  const generateRandomDrawings = useCallback((height) => {
    if (!height) return [];
    
    const items = [];
    const pageHeight = height; // This is the padded height
    
    // Check if it's a mobile device by window width
    const isMobile = window.innerWidth <= 768;
    
    // Adjust number of drawings based on both device type and page height
    // Removed explicit cap - rely on height calculation and distance checks
    const numDrawings = isMobile 
      ? Math.floor(height / 30) // For mobile, 1 drawing per 10px of height
      : Math.floor(height / 20); // For desktop, 1 drawing per 8px of height
      
    console.log(`Generating ${numDrawings} drawings for padded height ${height}px on ${isMobile ? 'mobile' : 'desktop'}`);
    
    // Minimum distance between drawings
    const minDrawingDistance = isMobile ? 200 : 350;
    
    // Track used positions
    const usedPositions = [];
    
    // Pre-select drawing types and positions
    for (let i = 0; i < numDrawings; i++) {
      // Select a random drawing
      const drawingType = drawings[Math.floor(Math.random() * drawings.length)];
      
      // Determine horizontal position based on index to ensure even distribution
      // Alternate between left (0-20%), center (20-70%), and right (70-100%)
      let x;
      if (i % 3 === 0) {
        // Left side - extend beyond left edge to prevent gap
        x = Math.random() * 25 - 15; // Range from -15% to +10% of screen width
      } else if (i % 3 === 1) {
        // Right side
        x = 70 + Math.random() * 30;
      } else {
        // Center - but use less of these (skip some)
        // Increase skip probability to reduce density significantly (e.g., skip 85%)
        if (Math.random() < 1) continue; 
        x = 20 + Math.random() * 50;
      }
      
      // Determine vertical position - place randomly within the entire padded height
      const y = Math.random() * pageHeight; 
      
      // Special case for masks, coffee, and beer - make 20% smaller
      const isSpecialDrawing = drawingType === "masks.svg" || drawingType === "coffee.svg" || drawingType === "jupiler.svg" || drawingType === "film.svg" || drawingType == "football.svg";
      
      // Base scale for drawings
      const baseScale = 0.15 + Math.random() * 0.35;  // 0.15 to 0.5
      
      // Apply 20% reduction for special drawings
      const scale = isSpecialDrawing ? baseScale * 0.7 : baseScale;
      
      // Check if position is clear (simple and efficient check)
      let positionClear = true;
      for (const pos of usedPositions) {
        // Calculate simple distance
        const xDist = (x - pos.x) * window.innerWidth / 100; // Convert % to px
        const yDist = y - pos.y;
        const distSquared = xDist * xDist + yDist * yDist;
        
        // Check minimum distance
        if (distSquared < 200 * 200) {
          positionClear = false;
          break;
        }
        
        // Check same drawing type - bigger distance
        if (pos.drawingType === drawingType && 
            distSquared < (200 * 2) * (200 * 2)) {
          positionClear = false;
          break;
        }
      }
      
      if (!positionClear) continue;
      
      // Determine which section this falls into (for z-index)
      const sectionName = x < 20 ? 'left' : (x > 70 ? 'right' : 'center');
      
      // Full 360 degree rotation as requested
      const rotation = Math.random() * 90 - 45;
      
      // Add the drawing
      items.push({
        src: drawingType,
        style: {
          position: 'absolute',
          left: `${x}%`,
          top: `${y}px`,
          width: '300px',  // Fixed base size
          height: '300px',
          transform: `scale(${scale}) rotate(${rotation}deg)`,
          opacity: 1,
          // Center drawings should appear below events (rule #3)
          zIndex: sectionName === 'center' ? -1 : (Math.random() > 0.7 ? 5 : -1),
          pointerEvents: 'none',
          mixBlendMode: 'multiply',
          filter: 'grayscale(100%) brightness(0) invert(8%) sepia(85%) saturate(6181%) hue-rotate(241deg) brightness(91%) contrast(118%)',
          transformOrigin: 'center center',
          margin: 0,
          padding: 0
        }
      });
      
      // Record the position
      usedPositions.push({ x, y, drawingType });
    }
    
    console.log(`Successfully generated ${items.length} drawings total`);
    return items;
  }, [drawings]);

  // Update drawings when content height changes - use a ref to ensure drawing stability
  useEffect(() => {
    // Ref to track if we've done an initial measurement
    const hasInitiallyMeasured = { current: false };
    
    // Function to measure the actual content height
    const measureHeight = () => {
      if (appRef.current) {
        // Get the actual scrollable height of the content
        const calculatedHeight = Math.max(
          document.documentElement.scrollHeight,
          document.body.scrollHeight,
          appRef.current.scrollHeight
        );
        
        // Only update if this is the first measurement or significant change
        setContentHeight(prevHeight => {
          if (!hasInitiallyMeasured.current) {
            hasInitiallyMeasured.current = true;
            console.log('Initial content height measured:', calculatedHeight);
            return calculatedHeight;
          }
          
          // For subsequent measurements, require significant change (100px)
          if (calculatedHeight > prevHeight + 100) {
            console.log('Content height updated:', calculatedHeight);
            return calculatedHeight;
          }
          
          return prevHeight;
        });
      }
    };
    
    // Use ResizeObserver for browsers that support it
    let resizeObserver;
    if (typeof ResizeObserver !== 'undefined') {
      resizeObserver = new ResizeObserver(() => {
        // Only measure if not already measured (for initial load)
        // or in response to device width changes (not content changes)
        if (!hasInitiallyMeasured.current) {
          measureHeight();
        }
      });
      
      if (appRef.current) {
        resizeObserver.observe(appRef.current);
      }
    } else {
      // Fallback - just measure once
      measureHeight();
    }
    
    // Always do one measurement on mount
    measureHeight();
    
    // Also add resize listener to detect device type changes
    const handleResize = () => {
      // Check if device type (mobile/desktop) has changed
      const isMobile = window.innerWidth <= 768;
      if (isMobile !== lastDeviceIsMobile.current) {
        console.log('Device type changed due to resize:', isMobile ? 'now mobile' : 'now desktop');
        // Force redrawing by resetting the flag
        hasGeneratedDrawings.current = false;
        
        // Measure again on device change
        measureHeight();
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    // Clean up observer and timer
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      if (window.heightMeasurementTimer) {
        clearTimeout(window.heightMeasurementTimer);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Keep dependency array empty for measurement

  // Simplify drawing generation to prevent layout shifts
  useEffect(() => {
    if (contentHeight > 0 && !hasGeneratedDrawings.current) {
      // Generate drawings only once when we have a valid height
      const paddedHeight = contentHeight + 1200;
      setRandomDrawings(generateRandomDrawings(paddedHeight));
      hasGeneratedDrawings.current = true;
      lastGeneratedHeight.current = paddedHeight;
      // Force scroll position again after drawings are generated
      preventScroll();
    }
  }, [contentHeight, generateRandomDrawings]);

  const handleEventSubmit = (e) => {
    e.preventDefault();
    // Here you can add logic to handle the submission
    console.log('Event submitted:', eventSubmission);
    setEventSubmission('');
  };

  // Add meta description update based on language
  useEffect(() => {
    // Update meta description based on current language
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      if (language === 'nl') {
        metaDescription.setAttribute('content', 'Jouw go-to plek voor evenementen in Gent, van feestjes tot culturele evenementen. Ontdek het bruisende ecosysteem van concerten, theater, comedy en meer. Dagelijks bijgewerkt.');
      } else {
        metaDescription.setAttribute('content', 'Your go-to place to look for events in Ghent, from parties to cultural events. Explore Ghent\'s vibrant ecosystem of concerts, theater, comedy and more. Updated daily.');
      }
    }
    
    // Update title based on current language - keep it simple as "Ghent Events"
    document.title = 'Ghent Events';
    
    // Update hreflang links
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    const currentUrl = window.location.href.split('?')[0]; // Remove query parameters
    
    if (canonicalLink) {
      canonicalLink.setAttribute('href', currentUrl);
    }
    
  }, [language]);

  return (
    <div ref={appRef} className="App">
      {/* Add canonical link */}
      <CanonicalLink path={currentPath} />
      
      {/* Add structured data */}
      <StructuredData data={generateWebsiteSchema()} />
      <StructuredData data={generateLocalBusinessSchema()} />
      <StructuredData data={generateFAQSchema()} />
      
      {/* Background Drawings */}
      <div className="background-drawings" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%', // Changed from 100vw to 100% to prevent horizontal overflow
        // Ensure container height matches the *last generated* height
        height: lastGeneratedHeight.current > 0 ? `${lastGeneratedHeight.current}px` : '100vh',
        overflow: 'hidden', // Changed from visible to hidden
        pointerEvents: 'none',
        margin: 0,
        padding: 0
      }}>
        {randomDrawings.map((drawing, index) => (
          <img 
            key={index}
            src={`/drawings/${drawing.src}`}
            alt=""
            style={{
              ...drawing.style,
              filter: 'grayscale(100%) brightness(0) invert(8%) sepia(85%) saturate(6181%) hue-rotate(241deg) brightness(91%) contrast(118%)',
            }}
            aria-hidden="true"
            loading="lazy"
          />
        ))}
      </div>
      
      {/* Scroll Progress Bar */}
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />

      <header
        className="header"
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Custom Animated SVG Background */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2560 1120" // Ensure this matches your SVG's viewBox
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "50%",
            zIndex: 0,
          }}
        >
          <defs>
            {/* Define a gradient for the stroke */}
            <linearGradient id="skylineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#1124c0" />
              <stop offset="100%" stopColor="#1124c0" />
            </linearGradient>
          </defs>
          <g
            transform="translate(0,1120) scale(0.1,-0.1)"
            fill="none" // Remove fill for stroke animation
            stroke="url(#skylineGradient)" // Apply gradient to stroke
            strokeWidth="20" // Adjust stroke width as needed
          >
            {/* <motion.path
              d="M10868 7803 c-13 -12 -32 -16 -72 -14 -86 3 -96 -6 -96 -92 0 -75 9
              -97 42 -97 10 0 18 -4 18 -9 0 -6 -5 -101 -11 -213 l-11 -203 -34 -56 -34 -57
              0 -158 c0 -148 -1 -160 -20 -172 -15 -9 -20 -23 -20 -60 0 -39 -5 -53 -25 -70
              -18 -16 -25 -32 -25 -60 0 -27 -6 -41 -22 -51 -18 -11 -22 -11 -30 3 -17 31
              -52 18 -111 -42 -55 -56 -57 -59 -57 -112 -1 -32 -7 -63 -15 -74 -8 -11 -15
              -34 -15 -52 0 -19 -6 -34 -15 -38 -13 -5 -15 -114 -15 -898 0 -882 0 -893 -20
              -906 -16 -9 -20 -23 -20 -62 l0 -50 -48 0 c-35 0 -54 -5 -65 -18 -13 -14 -34
              -19 -99 -21 -89 -2 -97 -7 -98 -56 0 -27 -6 -33 -53 -53 -39 -17 -71 -22 -130
              -22 -120 0 -117 4 -117 -155 l0 -135 -155 0 -155 0 0 240 c0 280 9 260 -124
              260 -102 0 -116 4 -116 39 0 46 -11 52 -86 49 l-69 -3 -3 -62 c-3 -67 -12 -78
              -30 -38 -7 15 -20 25 -32 25 -14 0 -20 7 -20 21 0 29 -18 59 -35 59 -13 0 -15
              39 -15 266 0 253 -1 266 -19 271 -25 6 -26 35 -46 873 -15 617 -17 646 -36
              662 -13 11 -19 29 -19 61 0 24 -6 63 -14 85 -8 22 -24 98 -36 169 -12 70 -26
              134 -32 141 -6 6 -25 12 -43 12 -18 0 -37 -6 -43 -12 -5 -7 -15 -49 -22 -93
              -6 -44 -19 -111 -28 -150 -15 -66 -16 -68 -22 -35 -5 34 -6 35 -56 38 l-52 3
              -26 142 c-15 78 -29 149 -32 159 -8 29 -34 29 -67 2 l-32 -27 0 -111 c0 -63
              -5 -119 -12 -130 -9 -17 -13 -9 -26 50 -9 38 -19 92 -22 119 -12 87 -11 86
              -63 83 l-47 -3 -8 -101 c-4 -55 -13 -120 -20 -144 -11 -38 -17 -45 -51 -54
              -23 -7 -42 -19 -46 -31 -7 -18 -9 -18 -19 9 -12 29 -38 173 -43 236 -2 27 -9
              37 -29 44 -14 5 -38 6 -52 2 -23 -5 -26 -12 -32 -73 -10 -96 -40 -205 -77
              -286 l-32 -69 23 -19 c13 -11 31 -19 40 -20 11 0 9 -3 -6 -9 l-23 -9 0 -657
              0 -657 -40 -53 -40 -53 -2 -398 -3 -398 -272 -2 -272 -2 -50 40 -49 39 -125 0
              c-144 0 -157 -6 -157 -71 0 -28 -4 -39 -15 -39 -13 0 -15 20 -15 124 0 87 -4
              126 -12 133 -15 12 -922 223 -956 223 -14 0 -27 -9 -34 -25 -10 -22 -18 -25
              -59 -25 -56 0 -69 -15 -69 -78 0 -26 -6 -45 -20 -57 -17 -15 -20 -31 -20 -102
              l0 -83 -134 0 c-149 0 -166 -6 -166 -61 l0 -29 -121 0 c-66 0 -129 -5 -140
              -10 -16 -9 -19 -22 -19 -87 l0 -77 -29 28 c-15 15 -40 53 -55 84 -15 31 -32
              68 -39 82 l-13 25 -288 3 c-205 2 -293 -1 -302 -9 -10 -8 -14 -41 -14 -125
              l0 -114 -63 0 c-36 0 -68 -5 -75 -12 -7 -7 -12 -37 -12 -67 0 -43 -3 -52 -12
              -43 -7 7 -38 12 -70 12 l-58 0 0 51 c0 45 -5 56 -42 100 -49 58 -70 61 -114 18
              l-33 -32 -54 47 c-30 25 -60 46 -67 46 -6 0 -54 -36 -106 -81 l-94 -81 0 -69
              0 -69 -198 0 -198 0 -58 -59 c-62 -63 -70 -94 -26 -99 19 -2 37 9 70 43 l44
              45 206 0 c147 0 209 3 218 12 7 7 12 42 12 89 l0 77 66 56 66 57 49 -46 c26
              -25 56 -45 66 -45 10 1 34 14 53 31 l35 29 17 -22 c12 -16 18 -42 18 -83 0 -34
              5 -66 12 -73 7 -7 38 -12 70 -12 44 0 58 -3 58 -15 0 -20 32 -35 74 -35 63 0
              66 5 66 86 l0 74 63 0 c36 0 68 5 75 12 8 8 12 50 12 125 l0 113 244 0 244 0
              30 -64 c37 -79 90 -138 148 -166 65 -32 74 -20 74 91 l0 88 138 3 137 3 3 42
              3 42 147 3 147 3 3 104 c2 91 5 106 22 118 14 10 20 26 20 54 l0 39 56 0
              c47 0 57 3 61 20 3 11 10 20 17 20 6 0 201 -43 434 -97 l422 -97 0 -122 c0
              -151 5 -159 91 -151 32 3 62 8 68 12 6 3 11 26 11 51 l0 44 98 0 c92 0 100 -2
              121 -24 48 -51 63 -53 389 -51 250 1 305 4 313 16 5 8 9 198 9 422 l0 409 38
              45 37 46 2 640 c3 628 3 640 23 654 15 11 20 25 20 64 0 43 -3 51 -25 61 -14
              6 -24 16 -21 22 2 6 19 55 37 109 l32 98 18 -68 c9 -38 25 -85 34 -107 15 -35
              15 -40 1 -55 -20 -22 -20 -60 0 -89 17 -25 75 -31 91 -9 6 6 16 51 22 99 l13
              87 34 7 c41 8 48 16 63 79 7 28 15 57 18 65 3 8 14 -14 25 -50 26 -87 35 -94
              86 -80 38 10 43 14 57 62 8 28 16 61 16 72 1 12 7 -10 15 -48 8 -38 19 -75 25
              -82 6 -8 29 -14 50 -14 38 0 39 -1 39 -35 0 -21 8 -43 19 -54 13 -13 21 -37
              23 -68 3 -44 5 -48 31 -51 45 -5 57 12 57 87 0 37 9 93 20 128 10 34 22 86
              26 115 l7 52 24 -99 c13 -55 26 -137 30 -183 4 -64 10 -85 24 -95 17 -12 19
              -61 39 -768 19 -730 20 -755 40 -771 19 -15 20 -28 20 -263 0 -245 5 -285 35
              -285 9 0 15 -13 17 -37 3 -33 7 -38 30 -41 19 -2 28 -10 30 -25 3 -19 10 -22
              57 -25 71 -4 101 21 101 85 0 24 5 43 10 43 6 0 10 -15 10 -34 0 -19 4 -37
              9 -40 4 -3 58 -9 119 -12 l112 -7 2 -246 3 -246 220 0 220 0 3 143 3 142 70 0
              c47 0 81 6 102 17 18 9 52 19 77 22 45 6 45 6 48 49 2 23 6 42 10 43 4 0 42 1
              85 2 62 2 81 6 91 20 9 12 27 17 58 17 56 0 68 13 68 71 0 38 4 48 20 52 20 5
              20 14 20 905 0 791 2 901 15 906 11 4 15 20 15 54 0 32 5 54 15 62 10 8 15
              31 15 70 0 38 5 62 14 70 13 10 15 4 18 -41 3 -59 20 -76 61 -65 25 6 27 10
              27 65 0 65 15 87 65 93 29 3 30 5 33 61 2 45 8 62 28 82 16 16 24 35 24 57 0
              22 7 37 20 46 19 11 20 23 20 190 l0 177 34 51 34 50 11 242 c7 133 9 251 6
              262 -4 13 -15 20 -31 20 -19 0 -24 5 -24 25 0 18 5 25 19 25 11 0 23 -5 27
              -11 3 -6 17 -9 30 -6 25 5 28 -10 7 -48 -23 -42 23 -490 54 -521 8 -8 30 -26
              49 -40 l34 -26 0 -187 0 -187 25 -22 c14 -12 25 -30 25 -41 0 -10 7 -24 15
              -31 10 -9 15 -31 15 -72 0 -75 12 -98 50 -98 25 0 30 -4 30 -25 0 -39 54 -59
              83 -30 20 20 35 2 39 -50 2 -29 9 -43 24 -49 29 -13 34 -72 34 -414 0 -169 4
              -281 10 -293 6 -10 20 -19 30 -19 20 0 20 -13 22 -857 l3 -858 199 -3 c138 -2
              203 1 212 9 9 8 14 33 14 75 l0 64 180 0 180 0 6 40 7 40 248 0 248 0 3 -67
              3 -68 139 -3 c118 -2 141 -5 152 -20 11 -15 40 -17 284 -19 172 -2 279 1 291
              7 15 9 19 22 19 70 l0 60 64 0 c73 0 76 3 76 84 l0 52 357 2 c269 2 359 5
              365 15 4 6 8 172 8 367 0 195 4 369 9 386 5 17 7 43 4 58 l-5 26 -328 0
              c-320 0 -329 -1 -335 -20 -5 -16 -15 -20 -56 -20 l-49 0 0 70 c0 68 1 70 25
              70 21 0 25 -5 25 -29 0 -36 22 -55 55 -47 25 6 28 13 29 76 1 32 4 35 34 38
              28 3 32 0 32 -22 0 -44 25 -58 94 -52 33 3 147 8 251 12 105 3 196 11 203 16
              6 6 12 30 12 55 0 30 5 45 15 49 10 4 15 20 15 50 l0 44 75 0 c60 0 75 -3
              75 -15 0 -8 -6 -15 -14 -15 -8 0 -18 -5 -22 -11 -11 -18 -5 -132 7 -140 6 -4
              57 -10 113 -13 127 -9 136 -12 136 -41 l0 -24 -137 -3 -138 -3 -3 -41 c-2 -28
              -11 -49 -28 -64 l-24 -23 0 -478 c0 -263 3 -484 6 -493 10 -25 74 -22 81 4 5
              18 14 20 73 20 60 0 69 -3 75 -20 5 -16 15 -20 50 -20 l44 0 3 -52 3 -53 125
              0 125 0 0 65 0 65 -40 5 c-35 4 -40 9 -43 33 -3 25 -1 27 37 27 37 0 44 4 80
              53 74 98 156 286 186 424 l7 33 381 2 381 3 60 163 c33 89 63 159 67 155 3 -5
              17 -48 30 -97 13 -48 35 -111 49 -140 l25 -51 63 -3 62 -3 45 97 45 96 0 678
              0 678 26 52 c14 28 34 74 43 103 l18 52 13 -40 c17 -55 64 -136 94 -164 24 -22
              29 -23 65 -13 69 21 242 245 340 439 l41 83 0 130 c0 80 4 130 10 130 6 0 10
              -50 10 -130 l0 -130 76 -123 c110 -175 200 -303 255 -360 48 -49 49 -50 96 -43
              26 3 53 13 60 23 7 9 24 52 39 97 29 88 44 114 44 76 0 -31 51 -140 73 -159
              16 -12 17 -74 17 -718 l0 -704 96 -135 c52 -75 102 -138 110 -141 27 -10 44 17
              44 70 0 42 40 178 96 327 3 8 30 -49 60 -130 52 -140 53 -148 56 -260 l3 -115
              567 -3 568 -2 39 78 c35 69 74 180 99 282 l9 35 12 -60 c21 -100 52 -207 75
              -255 28 -60 52 -63 93 -10 l31 41 33 -40 c33 -42 58 -51 77 -28 17 20 50 127
              70 227 l19 93 27 -107 c14 -59 42 -147 61 -195 35 -88 35 -89 35 -250 0 -93
              4 -171 10 -182 6 -11 21 -19 35 -19 l25 0 0 -204 c0 -218 -1 -216 51 -216 16 0
              19 -7 19 -41 0 -62 14 -79 62 -79 31 0 48 7 75 30 l34 30 254 0 c297 0 275
              -10 275 125 l0 85 71 68 c39 37 75 69 80 70 5 2 36 -19 68 -47 32 -28 66 -51
              75 -51 9 0 35 13 57 30 l41 30 27 -21 c24 -19 26 -29 31 -107 l5 -87 80 -3
              c72 -2 82 -5 90 -25 8 -18 19 -23 64 -25 91 -5 101 0 101 58 0 27 -4 51 -8
              54 -4 2 -7 20 -7 38 l1 33 74 3 c41 2 80 8 87 14 9 8 13 47 13 144 l0 134 295
              0 296 0 20 -47 c35 -83 107 -180 157 -213 57 -37 97 -38 97 0 0 18 -10 29 -38
              44 -51 27 -92 80 -147 190 l-45 91 -330 3 c-182 1 -340 0 -352 -3 -22 -5 -23
              -8 -23 -145 l0 -140 -68 0 c-98 0 -105 -8 -103 -108 1 -50 -2 -82 -9 -82 -5
              0 -10 4 -10 9 0 27 -34 41 -101 41 l-69 0 0 69 0 70 -59 45 c-33 25 -64 46
              -68 46 -5 0 -27 -13 -49 -30 -21 -16 -41 -30 -44 -30 -3 0 -35 25 -70 55 -36
              30 -70 55 -77 55 -7 0 -59 -44 -116 -97 l-105 -98 -1 -87 -1 -88 -241 0 -241
              0 -37 -32 -36 -32 -5 60 c-5 58 -5 59 -37 62 l-33 3 0 194 c0 206 -4 225 -51
              225 -18 0 -19 9 -19 159 0 153 -1 160 -29 222 -32 72 -85 251 -102 349 -12 71
              -19 80 -65 80 -43 0 -51 -14 -64 -101 -16 -102 -50 -249 -59 -249 -4 0 -17 14
              -29 30 -29 38 -54 38 -87 -2 -21 -25 -28 -29 -36 -17 -12 20 -46 169 -60 264
              -7 44 -17 86 -22 93 -5 6 -29 12 -53 12 -37 0 -44 -3 -49 -23 -3 -12 -14 -62
              -25 -112 -22 -101 -45 -173 -84 -258 l-27 -57 -514 0 -515 0 0 89 c0 85 -3 95
              -71 277 -85 226 -85 226 -111 222 -28 -4 -28 -5 -101 -220 l-63 -188 -57 80
              -57 81 0 699 0 699 -40 58 c-28 40 -44 76 -51 118 -6 33 -15 67 -19 75 -9 18
              -76 26 -95 10 -7 -6 -30 -58 -51 -116 -20 -58 -44 -108 -51 -111 -8 -3 -26 5
              -40 18 -36 34 -147 192 -237 336 l-76 122 0 136 c0 75 -5 145 -10 156 -9 15
              -22 19 -68 19 -32 0 -63 -5 -70 -12 -8 -8 -12 -57 -12 -156 l0 -144 -41 -81
              c-75 -150 -250 -387 -285 -387 -19 0 -47 48 -81 135 -15 39 -31 76 -36 83 -11
              14 -83 16 -92 2 -4 -6 -15 -43 -26 -83 -11 -39 -33 -96 -50 -124 l-29 -53 0
              -682 0 -681 -31 -69 c-35 -76 -59 -87 -82 -40 -12 23 -45 137 -90 307 -11 42
              -17 50 -39 53 -29 3 -24 13 -141 -298 l-43 -115 -381 -5 c-210 -3 -384 -7 -386 -10
              -3 -3 -14 -44 -26 -92 -29 -122 -72 -229 -132 -327 l-52 -84 -62 6 c-41 4 -66 2
              -74 -6 -17 -17 -14 -86 4 -93 8 -3 17 -22 21 -42 3 -21 13 -43 22 -50 13 -10
              10 -12 -21 -12 -36 0 -37 1 -37 36 0 55 -15 74 -58 74 -26 0 -41 6 -50 20 -11
              18 -23 20 -117 20 l-105 0 0 428 c0 416 1 428 20 440 13 8 20 23 20 42 l0 30
              129 0 c158 0 161 2 161 99 0 43 -5 72 -13 79 -6 6 -63 15 -125 21 -126 12 -145
              18 -110 35 20 10 23 20 26 75 2 38 -2 68 -9 77 -15 18 -249 20 -267 2 -7 -7
              -12 -29 -12 -49 0 -24 -5 -39 -15 -43 -11 -4 -15 -20 -15 -55 l0 -49 -122 -6 c-68
              -3 -162 -7 -210 -9 l-88 -3 0 32 c0 39 -11 44 -100 44 -77 0 -100 -6 -100 -26
              0 -9 -19 -14 -67 -16 l-68 -3 -3 -129 c-2 -97 1 -131 10 -138 16 -10 199 -10
              214 0 6 4 13 14 16 22 4 10 16 15 34 14 16 -1 131 -3 257 -3 l227 -1 0 -360
              0 -359 -356 -1 c-196 0 -360 -3 -365 -6 -5 -3 -9 -32 -9 -65 l0 -59 -58 0 c-32
              0 -63 -5 -70 -12 -7 -7 -12 -38 -12 -70 l0 -58 -228 0 c-215 0 -228 1 -245 20
              -15 18 -29 20 -147 20 l-130 0 0 59 c0 37 -5 63 -14 70 -9 8 -101 11 -317 9
              l-304 -3 -3 -37 -3 -38 -173 0 c-120 0 -176 -4 -184 -12 -7 -7 -12 -39 -12 -75
              l0 -63 -145 0 -145 0 0 861 c0 574 -3 867 -10 880 -6 10 -19 19 -29 19 -18
              0 -19 15 -23 318 -3 324 -8 362 -43 362 -11 0 -15 11 -15 39 0 22 -4 43 -10
              46 -5 3 -10 27 -10 53 0 67 -7 74 -76 70 -52 -3 -59 -5 -62 -25 -2 -15 -10 -23
              -23 -23 -17 0 -19 7 -19 58 0 45 -4 63 -19 76 -10 9 -21 35 -25 57 -3 22 -13
              42 -21 45 -12 5 -15 36 -15 182 l0 177 -54 50 -53 50 -12 95 c-22 168 -28 321 -14
              339 15 20 17 145 3 172 -13 23 -40 25 -62 2z m4165 -3920 c-7 -2 -19 -2 -25 0
              -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
              variants={drawingVariants}
              initial="hidden"
              animate="visible"
            /> */}
          </g>
        </svg>

        {/* Header Text */}
        <motion.div
          className="header-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.5 }} // Delay to start after SVG animation
          style={{ position: "relative", zIndex: 1, color: "#050101" }}
        >
          <motion.h1
            className="header-title"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Ghent Events
          </motion.h1>
          <motion.p
            className="header-subtitle"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
          >
            {language === 'nl' 
              ? 'Alle Gentse evenementen op één plek. Van lezingen aan de UGent tot feestjes in de Funke, en alles daartussen: comedy, theater, muziek en sport.' 
              : 'All Ghent events in one place. From lectures at UGent to parties at the Funke, and everything in between: comedy, theater, music and sports.'}
          </motion.p>
        </motion.div>

        {/* Add LanguageSwitcher */}
        <LanguageSwitcher language={language} setLanguage={setLanguage} />
        
        {/* Add Weekly Events Counter */}
        <UpcomingEventsCounter language={language} />
        
      </header>

      <EventList language={language} />
      <div className="event-submission-box">
        <h3>{language === 'nl' ? 'Mis je een evenement?' : "Don't see your event?"}</h3>
        <form onSubmit={handleEventSubmit}>
          <input
            type="text"
            value={eventSubmission}
            onChange={(e) => setEventSubmission(e.target.value)}
            placeholder={language === 'nl' ? 'Voer event URL in...' : 'Enter event URL...'}
            className="event-input"
          />
          <button type="submit" className="submit-button">
            {language === 'nl' ? 'Verstuur' : 'Submit'}
          </button>
        </form>
      </div>
      <Analytics />
    </div>
  );
}

export default App;
