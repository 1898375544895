// src/components/categoryColors.js
// Single source of truth for category styles

const CATEGORY_COLORS = {
  "Cinema": {
    backgroundColor: "#7950F2 !important",
    color: "#000000 !important",
    borderColor: "#7950F2 !important"
  },
  "Film": { // Alias for Cinema
    backgroundColor: "#7950F2 !important",
    color: "#000000 !important",
    borderColor: "#7950F2 !important"
  },
  "Theater & Dance": {
    backgroundColor: "#BA86FC !important",
    color: "#000000 !important",
    borderColor: "#BA86FC !important"
  },
  "Talks & Education": {
    backgroundColor: "#03DAC6 !important",
    color: "#000000 !important",
    borderColor: "#03DAC6 !important"
  },
  // Add other categories as needed with !important
  "Music": {
    backgroundColor: "#FF6B6B !important",
    color: "#000000 !important",
    borderColor: "#FF6B6B !important"
  },
  "Exhibition": {
    backgroundColor: "#00C9A7 !important",
    color: "#000000 !important",
    borderColor: "#00C9A7 !important"
  },
  "Academic": {
    backgroundColor: "#4D8076 !important",
    color: "#000000 !important",
    borderColor: "#4D8076 !important"
  },
  "Guided Tour": {
    backgroundColor: "#C56183 !important",
    color: "#000000 !important",
    borderColor: "#C56183 !important"
  },
  "Sport": {
    backgroundColor: "#FF9671 !important",
    color: "#000000 !important",
    borderColor: "#FF9671 !important"
  },
  "Festival": {
    backgroundColor: "#FFC75F !important",
    color: "#000000 !important",
    borderColor: "#FFC75F !important"
  },
  "Market": {
    backgroundColor: "#F9F871 !important",
    color: "#000000 !important",
    borderColor: "#F9F871 !important"
  },
  "Tech": {
    backgroundColor: "#6D7CE4 !important",
    color: "#000000 !important",
    borderColor: "#6D7CE4 !important"
  },
  "Vacation Camp": {
    backgroundColor: "#B8F2E6 !important",
    color: "#000000 !important",
    borderColor: "#B8F2E6 !important"
  },
  "Opendeurdag": {
    backgroundColor: "#FFA69E !important",
    color: "#000000 !important",
    borderColor: "#FFA69E !important"
  },
  "Workshop": {
    backgroundColor: "#93C6E7 !important",
    color: "#000000 !important",
    borderColor: "#93C6E7 !important"
  },
  "Party": {
    backgroundColor: "#E174FC !important",
    color: "#000000 !important",
    borderColor: "#E174FC !important"
  },
  "Other": {
    backgroundColor: "rgba(74, 78, 105, 0.2) !important",
    color: "#000000 !important",
    borderColor: "rgba(74, 78, 105, 0.5) !important"
  }
};

// Helper function to get style for a category
export const getCategoryStyle = (category) => {
  return CATEGORY_COLORS[category] || {};
};

export default CATEGORY_COLORS;