import React, { useRef } from 'react';
import { getCategoryStyle } from './categoryColors';

const CategoryFilter = ({ categories, selectedCategories, onCategoryChange }) => {
  const filterRef = useRef(null);
  
  // Debug logging
  console.log('Available Categories:', categories);
  console.log('Selected Categories:', selectedCategories);

  return (
    <div className="category-filter" ref={filterRef} tabIndex="0" aria-label="Category filter">
      <button
        className={`filter-tag all ${selectedCategories.length === 0 ? 'active' : ''}`}
        onClick={() => onCategoryChange([])}
      >
        All
      </button>
      {categories.map((category) => {
        // Convert category name to CSS-friendly class name
        const categoryClass = category.toLowerCase().replace(/[^a-z0-9]/g, '-');
        const isActive = selectedCategories.includes(category);
        
        // Only apply special styles when active
        const buttonStyle = isActive ? getCategoryStyle(category) : {};
        
        // Debug logging for each category
        console.log(`Category: ${category}, Class: ${categoryClass}, Active: ${isActive}, Style:`, buttonStyle);
        
        return (
          <button
            key={category}
            className={`filter-tag ${categoryClass} ${isActive ? 'active' : ''}`}
            style={{
              ...buttonStyle,
              padding: '10px 16px',
              borderRadius: '12px',
              cursor: 'pointer',
              transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
              fontWeight: '500',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
              position: 'relative',
              overflow: 'hidden',
              zIndex: '1',
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
              opacity: isActive ? '1' : '1'
            }}
            onClick={() => {
              if (isActive) {
                onCategoryChange(selectedCategories.filter(c => c !== category));
              } else {
                onCategoryChange([...selectedCategories, category]);
              }
            }}
          >
            {category}
          </button>
        );
      })}
    </div>
  );
};

export default CategoryFilter;