// src/components/EventList.js

import React, { useState, useMemo, useRef, useEffect } from "react";
import EventCard from "./EventCard";
import eventsDataNl from "../data/events.json";
import eventsDataEn from "../data/events_en.json";
import Fuse from "fuse.js";
import CategoryFilter from "./CategoryFilter";
import StructuredData, { generateEventsListSchema } from "./StructuredData";
import CATEGORY_COLORS from "./categoryColors";

const EventList = ({ language }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const today = new Date().toISOString().split('T')[0];
  const thirtyDaysAhead = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0];

  // Category order
  const categoryOrder = [
    "Music",
    "Theater & Dance",
    "Exhibition",
    "Academic",
    "Cinema",
    "Workshop",
    "Sport",
    "Festival",
    "Market",
    "Talks & Education",
    "Tech",
    "Party",
    "Other"
  ];

  // Use the correct events data based on language
  const eventsData = language === 'nl' ? eventsDataNl : eventsDataEn;

  // Initialize Fuse.js with the correct data
  const fuse = useMemo(() => {
    return new Fuse(eventsData, {
      keys: ["title", "location", "category", "date"],
      threshold: 0.3, // Adjust based on desired fuzziness
    });
  }, [eventsData]);

  // Filter events based on search query
  const filteredEvents = useMemo(() => {
    let filtered = eventsData;
    
    // Apply search filter
    if (searchQuery) {
      filtered = fuse.search(searchQuery).map(result => result.item);
    }
    
    // Map events to ensure all categories not in categoryOrder are set to "Other"
    filtered = filtered.map(event => {
      if (event.category && !categoryOrder.includes(event.category)) {
        return { ...event, category: "Other" };
      }
      return event;
    });
    
    // Apply category filter
    if (selectedCategories.length > 0) {
      filtered = filtered.filter(event => selectedCategories.includes(event.category));
    }
    
    return filtered;
  }, [searchQuery, selectedCategories, fuse, eventsData, categoryOrder]);

  // Filter and group events by date, stopping after 30 days from today
  const eventsByDate = useMemo(() => {
    return filteredEvents.reduce((acc, event) => {
      if (event.cleaned_dates >= today && event.cleaned_dates <= thirtyDaysAhead) {
        const date = event.cleaned_dates;
        if (!acc[date]) acc[date] = [];
        acc[date].push(event);
      }
      return acc;
    }, {});
  }, [filteredEvents, today, thirtyDaysAhead]);

  const dates = Object.keys(eventsByDate).sort();

  // Function to format date based on language
  const formatDate = (date) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    
    if (language === 'nl') {
      return new Date(date).toLocaleDateString("nl-NL", options);
    } else {
      return new Date(date).toLocaleDateString("en-GB", options);
    }
  };

  const dateRefs = useRef({});
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      // Use the middle of the screen to determine current position
      const scrollPosition = window.scrollY + window.innerHeight / 3;
      let newCurrentDate = '';

      // Check each date section and find the one currently in view
      for (const date of dates) {
        const element = dateRefs.current[date];
        if (element && scrollPosition >= element.offsetTop) {
          newCurrentDate = date;
        }
      }

      // Only update if we have a new date to show
      if (newCurrentDate) {
        setCurrentDate(newCurrentDate);
      }
    };

    // Add scroll event with a slight throttle for performance
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    
    // Initial check for the current date
    handleScroll();
    
    return () => window.removeEventListener('scroll', scrollListener);
  }, [dates]);

  // Add a style tag for custom category colors
  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement('style');
    styleElement.setAttribute('id', 'custom-category-colors');
    
    // Generate CSS from our centralized category colors
    let cssRules = '';
    
    // Add CSS rules for each category
    Object.entries(CATEGORY_COLORS).forEach(([category, style]) => {
      // Convert category name to CSS-friendly class name
      const categoryClass = category.toLowerCase().replace(/[^a-z0-9]/g, '-');
      
      // Add rule for event category
      cssRules += `
        html body .event-category.${categoryClass},
        html body button.filter-tag.${categoryClass}.active {
          background-color: ${style.backgroundColor};
          color: ${style.color};
          border-color: ${style.borderColor};
        }
      `;
    });
    
    styleElement.innerHTML = cssRules;
    
    // Append to head
    document.head.appendChild(styleElement);
    
    // Cleanup
    return () => {
      const existingStyle = document.getElementById('custom-category-colors');
      if (existingStyle) {
        document.head.removeChild(existingStyle);
      }
    };
  }, []);

  // Prepare events for structured data
  const prepareEventsForSchema = () => {
    // Get all events for the next 30 days
    const allEvents = [];
    
    Object.keys(eventsByDate).forEach(date => {
      eventsByDate[date].forEach(event => {
        // Skip events with null/undefined location
        if (!event.location) return;
        
        allEvents.push({
          name: event.title,
          description: `${event.title} in ${event.location}`,
          startDate: event.cleaned_dates,
          endDate: event.cleaned_dates,
          location: {
            name: event.location,
            address: event.location.includes(',') ? event.location.split(',')[0] : event.location
          },
          image: "",
          url: event.link,
          organizer: event.source || "Ghent Events"
        });
      });
    });
    
    return allEvents;
  };
  
  // Since prepareEventsForSchema is defined inline in the component 
  // and doesn't depend on any changing props or state other than eventsByDate,
  // we can just use eventsByDate as the dependency
  const eventsForSchema = useMemo(() => prepareEventsForSchema(), [eventsByDate]);

  return (
    <div className="event-list-container">
      {/* Add structured data for all events */}
      {eventsForSchema.length > 0 && (
        <StructuredData data={generateEventsListSchema(eventsForSchema)} />
      )}
    
      <div className="side-date-indicator">
        {currentDate && formatDate(currentDate)}
      </div>
      
      {/* Search Bar */}
      <div className="search-bar-container">
        <input
          type="text"
          placeholder={language === 'nl' ? "NTGent, Comedy, Angele, CineMangiare, Blauwdruk..." : "NTGent, Comedy, Angele, CineMangiare, Blauwdruk..."}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      <CategoryFilter
        categories={categoryOrder}
        selectedCategories={selectedCategories}
        onCategoryChange={setSelectedCategories}
      />

      {/* Event List */}
      <div className="event-list">
        {dates.length > 0 ? (
          dates.map((date) => (
            <div key={date} className="date-section" ref={el => dateRefs.current[date] = el}>
              <h2 className="date-heading">{formatDate(date)}</h2>
              <div className="events-container">
                {eventsByDate[date]
                  .sort((a, b) => {
                    const indexA = categoryOrder.indexOf(a.category);
                    const indexB = categoryOrder.indexOf(b.category);
                    if (indexA === -1 && indexB === -1) return 0; // Both categories are unlisted
                    if (indexA === -1) return 1; // a is unlisted, b is listed
                    if (indexB === -1) return -1; // b is unlisted, a is listed
                    return indexA - indexB; // Both are listed, sort by order
                  })
                  .map((event, idx) => (
                    <EventCard key={idx} event={event} index={idx} />
                  ))}
              </div>
            </div>
          ))
        ) : (
          <p className="no-events-message">
            {language === 'nl' ? 
              "Geen evenementen gevonden." : 
              "No events found."}
          </p>
        )}
      </div>
    </div>
  );
};

export default EventList;
